// ? TabsItems
import { mapActions, mapGetters } from 'vuex';
// ? SERVICE
import service        from '@/services/soporte'
import DialogForm     from '@/components/Dialogs/DialogForm/DialogForm.vue'

var currentDate = new Date().toISOString().substr(0,10);
export default {
    name : "AttendTicket",
    components : {
        DialogForm
    },
    props : {
        dialogAttend : {
            type    : Boolean,
            default : false
        },
        isEditAttend : {
            type    : Boolean,
            default : false
        },
        dataTicket : {
            type    : Object,
            default : () => {}
        },
    },
    data : () => ({
        valid           : true,
        lazy            : false,
        idTrTicket      : -1,
        idRlTicketResp  : -1,
        rlTicketFM      : "",
        estatus         : undefined,
        updated_at      : undefined,
        fechaAtencionProgramada : "",
        pickerAtencionInicio : {
            menu    : false,
            date    : currentDate
        },
        errores : {
            pickerAtencionInicio : false,
        },
        rules : {
            pickerAtencionInicio     : [ v => !!v || 'La fecha de atención es obligatoria' ],
        }
    }),
    watch : {
        'dataTicket' : function(){
            this.idTrTicket             = this.dataTicket.id_soporteTrTicket;
            this.updated_at             = this.dataTicket.updated_at;
            this.estatus                = this.dataTicket.estatus;

            this.readDataAsignacion();
        }
    },
    computed : {
        ...mapGetters( "Ticket", { dataSource : "getDataSource"} )
    },
    methods : {
        ...mapActions("DialogForm",["setDialog"]),
        ...mapActions( "Ticket", ["setDataSource"] ),
        getTitle() {
            return "Atención";
        },
        eventClose () {
            this.reset();
            // this.$emit("closeDialogAttend", false)
            this.setDialog({
                key    : "dlgAttend",
                status : false
            });
        },
        eventGen () {
            this.createAtencion();
        },
        validate () {
            return this.$refs.form.validate()
        },
        reset () {
            this.$refs.form.reset();
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        createAtencion(){
            let arrInfo = [];
            if( this.validate() ){
                let obj = this.buildObject();
                console.log( obj );
                arrInfo.push( this.$JsTable.getFormat( "soporteTrTicket", "Update", [ obj.objTrTicket ], ["id_soporteTrTicket"] ) );
                arrInfo.push( this.$JsTable.getFormat( "soporteRlTicketResponsable", "Update", [ obj.objRlTicketResp ], ["id_soporteRlTicketResponsable"] ) );

                service.SopU_atenderTicket( arrInfo ).then( result => {
                    if( this.$Msg.fn( {data : result, message : "Atención creada correctamente" } )) {
                        this.eventClose();
                        this.readTableTicket();
                    }
                })
            }
        },
        buildObject () {
            let fm = this.rlTicketFM.toISOString().substring(0, 10)+" "+this.rlTicketFM.toISOString().substring(11, 19);
            let objRlTicketResp = {
                id_soporteRlTicketResponsable   :   this.idRlTicketResp,
                fechaAtencionInicio             :   this.pickerAtencionInicio.date,
                updated_at                      :   fm
            }
            let objTrTicket = {
                id_soporteTrTicket  : this.idTrTicket,
                id_baseCtEstatus    : 3, // asignar 
                updated_at          : this.updated_at
            }

            return { objRlTicketResp, objTrTicket };
        },
        readTableTicket() {
            service.SopR_dataSourceTickets().then( result => {
                this.setDataSource( this.$JsTable.toObj( result.data ) );
            } )
        },
        async readDataAsignacion(){
            await service.SopR_dataAsignacion( { id_soporteTrTicket : this.idTrTicket } ).then( result => {
                let dataAssignInfo = this.$JsTable.toObj( result.data.dataAsignacion );
                if( dataAssignInfo.length > 0 ){
                    let dateAttentionProgram        = dataAssignInfo[0].fechaAtencionProgramada.split('T');
                    this.fechaAtencionProgramada    = dateAttentionProgram[0];
                    this.idRlTicketResp             = dataAssignInfo[0].idRlTicketResp;
                    this.rlTicketFM                 = new Date( dataAssignInfo[0].fm );
                }
            } )
        }
    },
    created() {

    },
    mounted() {
    }
}
